import router from '@/config/router';
import { message, notification } from 'ant-design-vue/lib/components';
import axios, { AxiosInstance, AxiosRequestConfig, Method } from 'axios';
import { ERROR_CODE_MAP, SUCCESS_CODE } from '@/lib/api/response_code';
import { state as globalState, service as globalService } from '@/modules/global';

/** 是否显示重新登录 */
export const isRelogin = { show: false };

const ExpiresInKey = 'Admin-Expires-In';

/** 默认接口参数配置 */
const default_config = {};

/** 移除空参数 */
export function clearNullParams(params: Record<string, any>): Record<string, any> {
  const _params: Record<string, any> = {
    _t: new Date().getTime(),
    ...params,
  };
  const _arr: Record<string, any>[] = Object.entries(_params)
    .filter(([key, value]) => value !== undefined && value !== null && value !== '')
    .map(([key, value]) => ({
      [key]: value,
    }));
  return Object.assign.apply({}, [{}, ..._arr]);
}

/** 获取Token */
export function getToken(hasBearer = false): string {
  const _authorization = localStorage.getItem('Authorization');
  if (hasBearer) {
    return `Bearer ${_authorization}`;
  } else {
    return _authorization as string;
  }
  // return _authorization ? (hasBearer ? `Bearer ${_authorization}` : _authorization) : '';
}

/** 设置Token */
export function setToken(authorization: string): void {
  localStorage.setItem('Authorization', authorization);
}

/** Token是否过期 */
export function isExpired(token: string) {
  const strings = token.split('.');
  const userinfo: Record<string, any> = JSON.parse(decodeURIComponent(escape(window.atob(strings[1].replace(/-/g, '+').replace(/_/g, '/')))));
  return userinfo.created;
}

/**
 * 基础请求提交
 */
export function request<T = any>({
  type,
  url,
  method,
  params = {},
  config = {},
  events = {},
  _axios = axios,
  responseType = 'json',
  localConfig = {},
}: {
  type: 'get' | 'post' | 'put' | 'delete' | 'upload' | 'download';
  url: string;
  method: string;
  params?: Record<string, any>;
  config?: AxiosRequestConfig;
  events?: Record<string, ((...args) => any) | undefined>;
  _axios?: AxiosInstance;
  responseType: 'arraybuffer' | 'blob' | 'document' | 'json' | 'text';
  localConfig?: Record<string, any>;
}): Promise<T> {
  return new Promise((resolve, reject) => {
    let getData = {};
    if (!['get', 'delete'].includes(method)) {
      if (params instanceof FormData) {
        getData = params;
      } else {
        getData = {
          ...params,
          ...default_config,
        };
      }
    }
    _axios
      .request({
        url,
        method: method as Method,
        cancelToken: config.cancelToken,
        responseType,
        params: ['get', 'delete'].includes(method)
          ? {
              ...params,
              ...default_config,
            }
          : {},
        // data: !['get', 'delete'].includes(method)
        //   ? params instanceof FormData
        //     ? params
        //     : {
        //         ...params,
        //         ...default_config,
        //       }
        //   : {},
        data: getData,
        ...config,
        headers: {
          Authorization: getToken(true),
          deviceInfo: navigator.userAgent || '',
          ...config.headers,
        },
        ...events,
      })
      .then(d => {
        const _data = d.data;
        if (_data.repCode !== undefined) {
          if (_data.success) {
            resolve(_data.repData);
          } else {
            reject(_data.repData);
          }
        } else if (_data.code === SUCCESS_CODE) {
          if (type === 'download') {
            resolve({
              filename: (
                /.*;?filename=(.*?);?$/.exec(d.headers['content-disposition']) ?? [`未命名文件${localConfig.defaultSuffix ?? ''}`]
              ).pop() as string,
              blob: _data,
            } as any);
          } else if (_data.data) {
            resolve(_data.data);
          } else {
            resolve(_data);
          }
        } else if (_data.size) {
          if (type === 'download') {
            resolve({
              filename: (
                /.*;?filename=(.*?);?$/.exec(d.headers['content-disposition']) ?? [`未命名文件${localConfig.defaultSuffix ?? ''}`]
              ).pop() as string,
              blob: _data,
            } as any);
          }
        } else {
          message.error(_data.msg);
          reject(_data);
        }
      })
      .catch(err => {
        let _err = err;
        if (axios.isCancel(err)) {
          console.log('Request canceled', err.message);
        } else if (err.response && ERROR_CODE_MAP[err.response.status]) {
          localStorage.removeItem('Authorization');
          message.error('登录超时，请重新登录。');
          isRelogin.show = false;
          router.push('/login');
          globalService.logout();
          reject(err);
          return;
        } else if (err?.response?.data) {
          _err = `[${err.response.status}]${err.response.data.errMsg || err.response.data.title || err.response.data.error}`;
        } else if (err?.response) {
          _err = `[${err.response.status}]${err.response.message || err.response.error}`;
        }
        message.error(_err);
        reject(_err);
      });
  });
}

/**
 * Get请求
 * @param {string} url URL地址
 * @param {object} params 参数
 * @param {AxiosRequestConfig} config
 */
export function get<T = any>(
  url: string,
  params: Record<string, any> = {},
  config: AxiosRequestConfig = {},
  _axios: AxiosInstance = axios,
): Promise<any> {
  const _params = clearNullParams(params);
  return request<T>({
    type: 'get',
    url,
    method: 'get',
    responseType: 'json',
    params: Object.assign.apply([], [{}, ...Object.entries(_params).map(([key, value]) => ({ [key]: value }))]),
    config,
    _axios,
    localConfig: {},
  });
}

/**
 * Post请求
 * @param {string} url URL地址
 * @param {object} params 参数
 * @param {AxiosRequestConfig} config
 */
export function post<T = any>(
  url: string,
  params: Record<string, any> = {},
  config: AxiosRequestConfig = {},
  _axios: AxiosInstance = axios,
): Promise<any> {
  const contentType = 'application/json';
  const _params = Array.isArray(params)
    ? params
    : {
        ...clearNullParams(params),
        ...default_config,
      };
  return request<T>({
    type: 'post',
    url,
    method: 'post',
    responseType: 'json',
    params: _params,
    config: {
      ...config,
      headers: {
        'Content-Type': contentType,
      },
    },
    _axios,
    localConfig: {},
  });
}

/**
 * Put请求
 * @param {string} url URL地址
 * @param {object} params 参数
 * @param {AxiosRequestConfig} config
 */
export function put<T = any>(
  url: string,
  params: Record<string, any> = {},
  config: AxiosRequestConfig = {},
  _axios: AxiosInstance = axios,
): Promise<any> {
  const contentType = 'application/json';
  const _params = Array.isArray(params)
    ? params
    : {
        ...clearNullParams(params),
        ...default_config,
      };
  return request<T>({
    type: 'put',
    url,
    method: 'put',
    responseType: 'json',
    params: _params,
    config: {
      ...config,
      headers: {
        'Content-Type': contentType,
      },
    },
    _axios,
    localConfig: {},
  });
}

/**
 * Delete请求
 * @param {string} url URL地址
 * @param {object} params 参数
 * @param {AxiosRequestConfig} config
 */
export function deletes<T = any>(
  url: string,
  params: Record<string, any> = {},
  config: AxiosRequestConfig = {},
  _axios: AxiosInstance = axios,
): Promise<any> {
  return request<T>({
    type: 'delete',
    url,
    method: 'delete',
    responseType: 'json',
    params: clearNullParams(params),
    config,
    _axios,
    localConfig: {},
  });
}

/**
 * Get请求（下载文件）
 * @param {string} url URL地址
 * @param {object} params 参数
 * @param {AxiosRequestConfig} config
 */
export function download<T = { filename: string; blob: string }>(
  url: string,
  params: Record<string, any> = {},
  config:
    | AxiosRequestConfig
    | {
        defaultSuffix: string | undefined;
      } = {},
  _axios: AxiosInstance = axios,
): Promise<T> {
  const _params = clearNullParams(params);
  return request<T>({
    type: 'download',
    url,
    method: 'get',
    params: Object.assign.apply([], [{}, ...Object.entries(_params).map(([key, value]) => ({ [key]: value }))]),
    config: config as AxiosRequestConfig,
    _axios,
    responseType: 'blob',
    localConfig: {
      defaultSuffix: config['defaultSuffix'] ?? '.txt',
    },
  });
}

/**
 * Post请求（下载文件）
 * @param {string} url URL地址
 * @param {object} params 参数
 * @param {AxiosRequestConfig} config
 */
export function downloadByPost<T = { filename: string; blob: string }>(
  url: string,
  params: Record<string, any> = {},
  config:
    | AxiosRequestConfig
    | {
        defaultSuffix: string | undefined;
      } = {},
  _axios: AxiosInstance = axios,
): Promise<T> {
  return request<T>({
    type: 'download',
    url,
    method: 'post',
    params: Array.isArray(params)
      ? params
      : {
          ...clearNullParams(params),
          ...default_config,
        },
    config: config as AxiosRequestConfig,
    _axios,
    responseType: 'blob',
    localConfig: {
      defaultSuffix: config['defaultSuffix'] ?? '.xlsx',
    },
  });
}

/**
 * Upload请求
 * @param {string} url URL地址
 * @param {object} params 参数
 * @param {AxiosRequestConfig} config
 */
export function upload<T = any>(
  url: string,
  params: Record<string, any> = {},
  config:
    | AxiosRequestConfig
    | {
        onUploadProgress: ((...args) => any) | undefined;
      } = {},
  _axios: AxiosInstance = axios,
): Promise<T> {
  const _params = new FormData();
  Object.entries(params).forEach(([key, value]) => {
    if (Array.isArray(value) && value.length && value[0] instanceof File) {
      (value as File[]).forEach(file => {
        _params.append(key, file);
      });
    } else {
      _params.append(key, value);
    }
  });
  const _config = config as any;
  return request<T>({
    type: 'upload',
    url,
    method: 'post',
    responseType: 'json',
    params: _params,
    events: {
      onUploadProgress: config.onUploadProgress,
    },
    config: {
      ...config,
      headers: {
        ..._config?.headers,
        'Content-Type': 'multipart/form-data',
      },
    } as AxiosRequestConfig,
    _axios,
    localConfig: {},
  });
}
