import { onMounted, ref } from 'vue';
import { useWebSocket } from '@vueuse/core';

export const useMessage = (url: string) => {
  const messageList = ref<any[]>([
    {
      title: '消息通知1',
      time: '4.20 19:00',
      content: '这是一条通知消息',
    },
    {
      title: '消息通知2',
      time: '4.20 19:00',
      content: '这是一条通知消息',
    },
  ]);

  //   const { status, data, send, open, close } = useWebSocket(url, {
  //     heartbeat: true,
  //     autoClose: true,
  //     autoReconnect: {
  //       retries: 3,
  //       delay: 2000,
  //       onFailed() {
  //         console.error('Failed to connect WebSocket after 3 retries');
  //       },
  //     },
  //     onConnected(ws) {
  //       console.log('websocket 连接成功！', ws);
  //     },
  //     onDisconnected(ws, event) {
  //       console.log('onDisconnected');
  //     },
  //     onError(ws, event) {
  //       console.log('onError');
  //     },
  //     onMessage(ws, event) {
  //       console.log('event.data', event.data);
  //       if (event.data) {
  //         const info = JSON.parse(event.data);
  //         console.log(info);
  //       }
  //     },
  //   });
  onMounted(() => {
    console.log(11111111);
  });
  return messageList;
};
